.navbarS {
    background: white;
    height: 4rem;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.2rem;
    position: sticky;
    top: 4rem;
    z-index: 998;
    box-shadow: 0px 3px 6px 0px rgb(112 112 112 / 32%);
  }
  
  .navbar-containerS {
    display: flex;
    justify-content: space-between;
    height: 5rem;
  }
  
  .container-servicesS {
    width: 80%;
    max-width: 1300px;
    margin-right: auto;
    margin-left: auto;
    padding-right: 3rem;
    padding-left: 12rem;
  }
  
  .navbar-logo-title {
    cursor: pointer;
    display: flex;
    align-items: center;
    color: #0A1B2D;
    text-decoration: none;
    font-weight: 900;
    font-size: 1.6rem;
    margin-right: 1rem;
  }
  .navbar-logo-title:hover{
    color: #F8B234;
  }
  
  .navbar-icon {
    padding: 0.8rem;
    width: 100%;
  }
  
  .nav-menuS {
    display: flex;
    align-items: center;
    justify-content: center;
    list-style: none;
    text-align: center;
    justify-content: end;
    margin: 0;
  }
  
  .nav-itemS {
    height: 5rem;
    border-bottom: 2px solid transparent;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .nav-itemS:hover .nav-linkServices{
    box-shadow: inset 500px 0 0 0 #F8B234;
    color: white;
    border-radius: 0.6rem;
    border-right: 2px solid transparent;
  }
  
  .nav-linkServices {
    color: #0A1B2D;
    display: flex;
    align-items: center;
    text-decoration: none;
    padding: 0.2rem 1rem;
    font-family: 'Roboto', sans-serif;
    font-weight: normal;
    font-size: 1rem;
    height: auto;
    margin: 0 0.2rem;
    border-right: 2px solid #0A1B2D;
  }
  .nav-menuS .nav-itemS:nth-child(3) .nav-linkServices{
    border-right: none;
  }
  .menu-icon svg {
    color: currentColor !important;
  }
  /* .fa-bars {
    fill: #0A1B2D;
  } */
  .menu-icon {
    display: none;
  }
  .nav-image{
    display: none;
  }
  
  @media (min-width: 2560px) {
    .container-servicesS{
        max-width: 2200px;
    }
  }
  @media (max-width: 1200px) {
    .container-servicesS {
      padding-right: 3rem;
      padding-left: 3rem;
    }
  }
  
  @media screen and (max-width: 960px) {
    .container-servicesS{
      display: flex;
      justify-content: center;
      padding: 0;
    }
    .NavbarItems {
      position: relative;
    }
    .nav-menuS {
      display: flex;
      flex-direction: column;
      width: 100%;
      height: 92vh;
      position: absolute;
      top: 0;
      left: -100%;
      opacity: 1;
      transition: all 0.5s ease;
      justify-content: center;
    }
  
    .nav-menuS.active {
      background-image: url(../assents/Img/background.png);
      background-size: cover;
      background-repeat: no-repeat;
      left: 0;
      opacity: 1;
      transition: all 0.6s ease;
      z-index: 1;
      top: 0;
      height: 94vh;
    }
  
    .nav-linkServices {
      text-align: center;
      padding: 2rem;
      width: 100%;
      display: table;
      color: #fff;
      font-size: 1.3rem;
      border-right: none !important;
      box-shadow: none !important;
      border-radius: 0.6rem;
    }
  
    .nav-itemS:hover {
      border: none;
    }
    .nav-itemS:hover .nav-linkServices{
      box-shadow: inset 500px 0 0 0 transparent;
      border-radius: 0;
      color: #F8B234;
      transform: scale(1.2);
      transition: all 0.3s ease;
    }
  
    .nav-itemS {
      width: 100%;
    }

    .menu-icon {
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      transform: translate(-100%, 30%);
      font-size: 1.8rem;
      cursor: pointer;
      z-index: 2;
      color: #0A1B2D;
    }
    .menu-icon.active{
      color: #fff;
    }
    .fa-times {
      color: #fff;
      font-size: 2rem;
    }
    /* .nav-btn {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 120px;
    } */
    .nav-image{
      display: block;
      width: 8rem;
    }
    
  }