.navbarMain {
    background: white;
    height: 4rem;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.2rem;
    position: sticky;
    top: 0;
    z-index: 999;
    box-shadow: 0px 3px 6px 0px rgb(112 112 112 / 32%);
  }
  
  .navbarMain-container {
    display: flex;
    justify-content: space-between;
    height: 5rem;
  }
  
  .container {
    width: 80%;
    max-width: 1300px;
    margin-right: auto;
    margin-left: auto;
    padding-right: 3rem;
    padding-left: 3rem;
  }
  
  .navbarMain-logo {
    cursor: pointer;
    display: flex;
    align-items: center;
    width: 14rem;
  }
  
  .navbarMain-icon {
    padding: 0.5rem 0.8rem 0.5rem 0.8rem;
    width: 100%;
  }
  
  .nav-menu {
    display: flex;
    align-items: center;
    justify-content: center;
    list-style: none;
    text-align: center;
    justify-content: end;
    margin: 0;
    padding: 0;
  }
  
  .nav-itemMain {
    height: 5rem;
    border-bottom: 2px solid transparent;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .nav-itemMain:hover .nav-links{
    box-shadow: inset 500px 0 0 0 #0a1b2d;
    color: white;
    border-radius: 0.6rem;
  }
  
  .nav-links {
    color: #0A1B2D;
    display: flex;
    align-items: center;
    text-decoration: none;
    padding: 0.2rem 1rem;
    font-family: 'Roboto', sans-serif;
    font-weight: normal;
    font-size: 1rem;
    height: auto;
    margin: 0 0.2rem;
    border-right: 2px solid #0A1B2D;
  }
  /* .nav-menu .nav-itemMain:nth-child(5) .nav-links{
    border-right: none;
  } */
  .menu-icon svg {
    color: currentColor !important;
  }
  /* .fa-bars {
    fill: #0A1B2D;
  } */
  .menu-icon {
    display: none;
  }
  .nav-image{
    display: none;
  }
  @media (min-width: 2560px) {
    .container{
        max-width: 2200px;
    }
  }
  @media screen and (max-width: 960px) {
    .NavbarItems {
      position: relative;
    }
  
    .nav-menu {
      display: flex;
      flex-direction: column;
      width: 100%;
      height: 100vh;
      position: absolute;
      top: 0;
      left: -100%;
      opacity: 1;
      transition: all 0.5s ease;
      justify-content: center;
    }
  
    .nav-menu.active {
      background-image: url(../assents/Img/background.png);
      background-size: cover;
      background-repeat: no-repeat;
      left: 0;
      opacity: 1;
      /* transition: all 0.6s ease; */
      z-index: 1;
      top: 0;
      height: 100vh;
    }
  
    .nav-links {
      text-align: center;
      padding: 2rem;
      width: 100%;
      display: table;
      color: #fff;
      font-size: 1.3rem;
      border-right: none !important;
      box-shadow: none !important;
      border-radius: 0 !important;
    }
  
    .nav-itemMain:hover {
      border: none;
    }
    .nav-itemMain:hover .nav-links{
      box-shadow: inset 500px 0 0 0 transparent;
      border-radius: 0;
      color: #F8B234;
      transform: scale(1.2);
      transition: all 0.3s ease;
    }
  
    .nav-itemMain {
      width: 100%;
    }
  
    .navbarMain-logo {
      position: absolute;
      top: 0;
      left: 0;
    }

    .menu-icon {
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      transform: translate(-100%, 30%);
      font-size: 1.8rem;
      cursor: pointer;
      z-index: 2;
      color: #0A1B2D;
    }
    .menu-icon.active{
      color: #fff;
    }
    .fa-times {
      color: #fff;
      font-size: 2rem;
    }
    /* .nav-btn {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 120px;
    } */
    .nav-image{
      display: block;
      width: 8rem;
    }
  }