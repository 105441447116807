.main-video-container{
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.2rem;
    color: #0A1B2D;
    font-family: 'Poppins', sans-serif;
    margin: 0 0 3rem 0;
}
  
.video-container {
    width: auto;
    max-width: 1300px;
    margin-right: auto;
    margin-left: auto;
    padding-right: 3rem;
    padding-left: 3rem;
}

@media (min-width: 2560px) {
    .video-container{
        max-width: 2200px;
    }
}
@media screen and (max-width: 960px) {
    .video-container {
        padding-right: 0;
        padding-left: 0;
    }
    .video-container{
        width: 100%!important;
    }
    .video-container .ReactPlayer{
        width: 90%!important;
    }
}