.description-card {
    height: auto;
    font-size: 1rem;
    color: #0A1B2D;
    font-family: 'Poppins', sans-serif;
    margin: 4rem 0;
    margin-bottom: 0;
}
  
.container-sofware {
    width: 100%;
    max-width: 1300px;
    margin-left: auto;
    padding-left: 3rem;
    padding-top: 2rem;
}
.row-description{
    margin: 0;
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
}
.row-description .col h1{
    margin-top: 3rem;
    margin-bottom: 2rem;
}
.row-description .col p{
    text-align: justify;
}
.col-software-img{
    display: flex;
    justify-content: flex-end;
    padding: 0;
}
.col-software-img img{
    width: 95%;
}
.soft-text{
    padding: 2rem 2rem 0 2rem;
    text-align: justify;
}
@media (min-width: 2560px) {
    .container-sofware{
        max-width: 2200px;
    }
    .container-sofware {
        width: 100%;
    }
}
@media screen and (max-width: 1370px) {
    .container-sofware {
        width: 80%;
    }
}
@media screen and (max-width: 1200px) {
    .container-sofware {
        width: 90%;
    }
}
@media screen and (max-width: 960px) {
    .container-sofware{
        padding-right: 1rem;
        padding-left: 1rem;
        padding-top: 0;
        width: 100%;
    }
    .row-description .col h1{
        text-align: center;
    }
    .col-software-img img{
        width: 100%;
    }
}
@media screen and (max-width: 768px){
    .col-software-img img {
        width: 30%;
    }
}
@media screen and (max-width: 500px){
    .col-software-img img {
        width: 50%;
    }
}