.form-card {
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1rem;
    color: #0A1B2D;
    font-family: 'Poppins', sans-serif;
    margin: 2.5rem 0;
}
.form-max-width {
    width: auto;
    max-width: 1300px;
    margin-right: auto;
    margin-left: auto;
    padding-right: 3rem;
    padding-left: 3rem;
    display: flex;
    justify-content: center;
}
.form-container{
    width: 50vw;
    background: rgb(255 255 255 / 10%);
}
.border-form{
    background: white;
    height: 2rem;
    box-shadow: 0px 3px 6px rgb(0 0 0 / 12%);
    display: flex;
    justify-content: center;
    align-content: center;
    padding-top: 0.5rem;
}
.form-icon{
    height: 1.7rem;
}
.form{
    padding: 2rem;
    color: #0A1B2D;
}
.btn-send{
    background: #0A1B2D;
    border: 1px solid #0A1B2D;
    padding: 0.3rem 2rem;
    border-radius: 0.8rem;
    margin-top: 1rem;
}
.row-card-icon{
    display: flex;
    justify-content: center;
}
.col-contact-card{
    height: auto;
    width: 15rem;
    margin: 1rem 0;
}
.line-white{
    width: 80%;
}
.dont-show{
    visibility: hidden;
}
.confirmation-container{
    position: fixed;
    width: 100%;
    height: 100vh;
    top: 0;
    left: 0;
    background: transparent radial-gradient(closest-side at 50% 50%, #7A7A7A 0%, rgb(61 61 61 / 60%) 100%) 0% 0% no-repeat padding-box;
    display: flex;
    justify-content: center;
    align-items: center;
    visibility: visible;
}
.confirmation-light{
    background: transparent radial-gradient(closest-side at 51% 47%, #CDCDCDA8 0%, rgb(114 114 114 / 80%) 100%) 0% 0% no-repeat padding-box;
    margin-top: 9vh;
    width: 70%;
    height: 80vh;
}
.confirmation-imagen{
    height: 90%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.exitBtn{
    position: absolute;
    top: 7rem;
    right: 16rem;
    color: white;
    cursor: pointer;
    font-size: 2rem;
}
.yellow-confirmation{
    background: #F8B234 0% 0% no-repeat padding-box;
    opacity: 1;
    color: white;
    width: 100%;
    text-align: center;
    height: 10%;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 0.8rem;
    font-family: 'roboto', sans-serif;
}
.yellow-confirmation span{
    font-size: 1rem;
    font-weight: bold;
    margin-right: 0.25vw;
}
.fondo-uno {
    background: #0A1B2D 0% 0% no-repeat padding-box;
    width: 38%;
    height: 60%;
    clip-path: polygon(0% 0%, 100% 0%, 100% 83%, 79% 83%, 70% 100%, 60% 83%, 0 82%);
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-family: 'roboto', sans-serif;
    border: 2px solid #FFFFFF;
}
.fondo-uno p {
    border-bottom: 3px solid #fff;
    padding-bottom: 3%;
    text-align: center;
    width: 80%;
    margin-bottom: 2%;
    font-size: 0.95rem;
}
.fondo-uno h1{
    text-align: right;
    width: 80%;
    font-weight: bold;
}
.fondo-uno h4{
    font-size: 1.5rem;
    text-align: center;
    max-width: 80%;
    font-weight: bold;
}
.fondo-dos{
    position: absolute;
    bottom: 35%;
    left: 31%;
    width: 25rem;
    height: 20rem;
    clip-path: polygon(0% 0%, 100% 0%, 100% 85%, 30% 85%, 22% 100%, 13% 85%, 0 85%);
    background: #0A1B2D 0% 0% no-repeat padding-box;
    border: 2px solid #FFFFFF;
    opacity: 0.25;
}
.img-confir{
    width: 8rem;
    margin-right: -5rem;
    z-index: 1;
    margin-top: -25%;
}
@media (min-width: 2560px) {
    .form-max-width{
        max-width: 2200px;
    }
}
@media screen and (max-width: 960px) {
    .form-max-width{
        padding-right: 1rem;
        padding-left: 1rem;
    }
    .top-description-container {
        padding: 1rem 1rem;
    }
    .form-container{
        width: 80vw;
    }
    .line-white {
        width: 100%;
    }    
    .col-contact-card {
        width: 12rem;
    }
}