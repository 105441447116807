.footer-container {
    width: 100%;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.row-menu-footer{
    width: 70%;
    max-width: 1300px;
    margin-right: auto;
    margin-left: auto;
    padding-right: 3rem;
    padding-left: 3rem;
}
/* .row{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
} */
.row-menu-footer .col{
  /*   border: 2px solid yellow; */
    height: 10rem;
    display: flex;
    align-items: center;
    justify-content: center;
}
.row-menu-footer .row{
        width: 100%;
}
.row-menu-footer .row .col a{
    color: white;
    text-decoration: none;
}
.circle-container{
    height: 7rem;
    width: 7rem;
    background: #0A1B2D;
    cursor: pointer;
    box-shadow: inset 0 0 0 0.5rem rgb(255 255 255), 0px 4px 3px 0px rgb(0 0 0 / 24%);
    transition: all 0.4s ease-in-out;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
  /*   border: 0.5rem solid white; */
}
.circle-container img{
    height: 50%;
    width: auto;
}
.circle-info{
    position: absolute;
    background: rgba(249,179,52, 0.9);
    width: 6rem;
    height: 6rem;
    border-radius: 50%;
    overflow: hidden;
    opacity: 0;
    transition: all 0.4s ease-in-out;
    transform: scale(0);
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.2rem;
}
.circle-container:hover .circle-info {
      transform: scale(1);
      opacity: 1;
}
.row-text{
    height: 5.5rem;
    width: 90%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.1rem;
    color: #0A1B2D;
    font-family: 'Poppins', sans-serif;
    font-weight: lighter;
    border-top: 2px solid #0A1B2D;
}


@media (min-width: 2560px) {
    .row-menu-footer{
        max-width: 2200px;
    }
}
@media screen and (max-width: 960px) {
    .row-menu-footer {
        width: 90%;
    }
    .row-menu-footer .col {
        height: 7rem;
    }
    .circle-container {
        height: 5rem;
        width: 5rem;
        box-shadow: inset 0 0 0 0.3rem rgb(255 255 255), 0px 4px 3px 0px rgb(0 0 0 / 24%);
    }
    .circle-info{ 
        height: 4rem;
        width: 4rem;
    }
}