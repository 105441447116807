.banner-container{
    width: 100%;
    height: 37vh;
    position: relative;
    text-align: center;
    color: white;
    overflow: hidden;
    display: flex;
    align-content: center;
    justify-content: center;
}
.banner-img{
    height: 100%;
    width: 100%;
}
.centered {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 3rem;
    width: 80%;
}


@media screen and (max-width: 960px) {
    .banner-container {
        height: 20vh;
    }
    .banner-img{
        width: 200%;
    }
    .centered{
        font-size: 2rem;
    }
}
