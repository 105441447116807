*{
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'roboto', sans-serif;
}
html {
  box-sizing: border-box;
  scroll-behavior: smooth;
}
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #F5F5F5;
  border-radius: 10px;
}
::-webkit-scrollbar {
  width: 10px;
  background-color: #F5F5F5;
}
::-webkit-scrollbar-thumb{
  background: #0a1b2d;
  border-radius: 6px;
}
::selection {
  color: #fff;
  background: #f9b334;
}
@media all and (min-device-width: 2560px) {
  html{
    font-size: 25px;
  }
}
@media all and (max-device-width: 1440px) {
  html{
    font-size: 14px;
  }
}